import { Suspense, lazy, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import getTheme from "../config/theme";
import { CssBaseline, ThemeProvider } from "material-ui-core";

const Login = lazy(() => import("../pages/Login"));
const CuentaCorriente = lazy(() => import("../pages/CuentaCorriente"));
const PagePrincipal = lazy(() => import("../pages/PaginaPrincipal"));

const Ruta = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);
  const handleThemeChange = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login isDarkMode={darkMode} />} />
            <Route>
              <Route path="/cta-corriente" element={<CuentaCorriente/>} />
              <Route path="/home" element={<PagePrincipal isDarkMode={darkMode}/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default Ruta;
