
import { Box } from "@mui/material";
import Router from "./router";
import { TokenProvider } from "./contexts/InfobackContext";
import Ruta from "./router";
// import { TokenProvider } from "./contexts/InfobackContext";
const App = () => {

  return (
  <TokenProvider>
    <Ruta/>
  </TokenProvider>
  );
}



export default App;
