import { createTheme, Theme } from '@mui/material/styles';

const getCssVariableValue = (variable: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

const getTheme = (darkMode: boolean): Theme => {
  const primaryMainColor = darkMode
    ? getCssVariableValue('--boton-800-primario') || '#6CD39B': '#42C67E';

  return createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: darkMode ? '#121212' : '#FFFFFF',
        paper: darkMode ? '#121212' : '#CFD3D8',
      },
      text: {
        primary: darkMode ? '#E7EFFC' : '#1E2947',
        secondary: darkMode ? '#A6E5C3' : ' #3CB473',
      },
      primary: {
        main: primaryMainColor,
      },
      secondary: {
        main: darkMode ? '#79DD8B' : '#42C67E',
      },
    },
    typography: {
      fontFamily: ['GothamPro', 'Poppins', 'sans-serif'].join(','),
    },
  });
};

export default getTheme;
